import toast, { Toaster } from "react-hot-toast";
import { useState } from "react";
import Logo from "pages/assets/logos/logo-dark.png";
import LoginImage from "pages/assets/images/login_two.svg";
import axios from "config/axios";
import { useForm } from "react-hook-form";
import { errorColor } from "services/colors/constants";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from "middlewares/hooks/useAuth";
import ProgressBar from "@badrap/bar-of-progress";
import Footer from "pages/components/footer";
const RegisterDevice = ({ setRoles }) => {
  const [remeber, setRemember] = useState(false);
  const onRemember = () => {
    remeber === true ? setRemember(false) : setRemember(true);
  };
  const progress = new ProgressBar({ color: "#020f46", size: 4 });
  const navigate = useNavigate();
  const location = useLocation();
  const { setAuth } = useAuth();
  const [disabled, setDisabled] = useState(false);
  const from = location.state?.from?.pathname || "/dashboard";
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onLoginAction = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const response = await axios.post("/sessions", data);

      const accesstoken = response?.data?.data?.access_token;
      const refreshtoken = response?.data?.data?.refresh_token;
      const sessionid = response?.data?.data?.session_id;
      setAuth({ sessionid, accesstoken, refreshtoken });
      const verify = {
        refresh: refreshtoken,
        id: sessionid,
        access: accesstoken,
      };
      localStorage["verify"] = JSON.stringify(verify);
      localStorage["roles"] = JSON.stringify(response.data?.data?.permissions);
      localStorage["user_info"] = JSON.stringify(
        response.data?.data?.user_info
      );
      setRoles(localStorage?.roles);
      navigate(from, { replace: true });
      progress.finish();
    } catch (error) {
      if (!error?.response) {
        setDisabled(false);
        toast.error("No server response", {
          style: { background: errorColor, color: "#000" },
        });
        progress.finish();
      } else {
        setDisabled(false);
        progress.finish();
        toast.error(error?.response?.data?.messages, {
          style: { background: errorColor, color: "#000" },
        });
      }
    }
  };
  return (
    <>
      <Toaster />
      <div className="page-wrapper" id="main-wrapper">
        <div className="position-relative overflow-hidden radial-gradient max-vh-100">
          <div className="position-relative z-index-1">
            <div className="row">
              <div className="col-xl-5 col-xxl-4">
                <div className="authentication-login min-vh-100 bg-body row justify-content-center align-items-center p-4">
                  <div className="col-sm-8 col-md-6 col-xl-9">
                    <h6 className="mb-3 fs-6 fw-bolder text-center">
                      Register New Device
                    </h6>
                    <form
                      autoComplete="off"
                      onSubmit={handleSubmit(onLoginAction)}
                    >
                  
                      <div className="mb-4">
                        <label htmlFor="password" className="form-label">
                          Enter Administration Provided Code
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          autoComplete="new-password"
                          placeholder="Enter Code Here"
                          autoFocus={true}
                          {...register("password", { required: true })}
                        />
                        {errors?.password?.type === "required" && (
                          <p className="text-small m-0 text-danger help-block">
                            Please provide a code
                          </p>
                        )}
                      </div>
                      <button
                        disabled={disabled}
                        className="btn btn-primary w-100 py-8 mb-4 rounded-2"
                      >
                        {!disabled && "Register New Device"}{" "}
                        {disabled && "Please Wait ....."}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RegisterDevice;
